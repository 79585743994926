import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { Theme } from '../../theme'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Icon = styled(FontAwesomeIcon)`
	border-radius: 50%;
	padding: 0;
	border: 1px solid #999;
	color: ${Theme.colors.white};
`
const Item = styled.div`
	font-size: 16px;
	padding: 15px 0;
	cursor: pointer;
	opacity: 0.7;

	&:hover {
		opacity: 1;
		color: ${Theme.colors.secondary};
	}

	span {
		margin-left: 8px;
	}

	${props =>
		props.isSelected &&
		css`
			opacity: 1;
			${Icon} {
				color: ${Theme.colors.secondary};
				border: none;
			}
		`}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const RadioItem = ({ label, onClick, isSelected = false }) => {
	return (
		<Item isSelected={isSelected} onClick={onClick}>
			<Icon icon={isSelected ? faCheckCircle : faCircle} />
			<span>{label}</span>
		</Item>
	)
}
export default RadioItem
