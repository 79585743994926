import axios from 'axios'
import { pluralize } from 'inflection'
import { isArray } from 'lodash'
import { Config } from '../config'

export const apiCall = async (uri, params = {}, useDeserialize = true, axiosOptions = { method: 'get' }) => {
	// Make the call
	const url = `${Config.apiUrl}/${uri.replace(/^\//, '')}`
	const result = await axios(url, {
		params,
		headers: {
			'X-Api-Key': Config.apiKey,
		},
		...axiosOptions,
	})

	// Deserialize
	return useDeserialize ? deserialize(result.data) : result.data
}

export const deserialize = data => {
	// Get the included records
	const included = data.included ? data.included.map(inc => deserializeModel(inc)) : []

	// Parse the models
	const items = isArray(data.data) ? data.data : [data.data]
	const models = items.map(model => deserializeModel(model))

	// Now link 'em up
	included.forEach(inc => linkModel(inc, included))
	models.forEach(model => linkModel(model, included))

	return models
}

const deserializeModel = (obj, included) => {
	return {
		id: obj.id,
		modelType: obj.type,
		...obj.attributes,
		relationships: obj.relationships,
	}
}

const linkModel = (model, included) => {
	// Anything there?
	const rels = model.relationships
	if (!rels) return

	// Go through it
	for (let attribute in rels) {
		const rel = rels[attribute]
		const data = isArray(rel.data) ? rel.data : [rel.data]
		const values = data.map(obj => included.find(inc => inc.id === obj.id && inc.modelType === obj.type))

		// Set value
		model[attribute] =
			values.length > 1 || attribute === pluralize(attribute) ? values : values.length > 0 ? values[0] : null
	}

	// Delete relationships
	delete model.relationships
}
