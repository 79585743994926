import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useMemo } from 'react'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import { negenTweeAutocomplete } from '../../api/negenTwee'
import { Theme } from '../../theme'
import { getAutocompleteIcon } from '../../utils/icons'
import { useDictionary } from '../hooks/useDictionary'
import { useWidget } from '../hooks/useWidget'
import NegenTweeAutocompleteSelectOption from './NegenTweeAutocompleteSelectOption'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	background: white;
	display: flex;
	width: 100%;
	align-items: center;
	border-radius: none;
	height: 60px;
	font-size: 16px;

	input {
		line-height: 40px;
		height: 40px;
	}
`
const Label = styled.label`
	font-weight: bold;
	padding: 0 16px;
	margin-bottom: 0;
	line-height: 40px;
`

const SingleValueLabel = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow-x: hidden;
	max-width: calc(100% - 52px);
	line-height: 25px;
	font-size: 18px;
	color: ${Theme.colors.body};

	:focus & {
		background: red !important;
	}
`

// ███████╗███████╗██╗     ███████╗ ██████╗████████╗    ███████╗████████╗██╗   ██╗██╗     ███████╗
// ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝    ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝
// ███████╗█████╗  ██║     █████╗  ██║        ██║       ███████╗   ██║    ╚████╔╝ ██║     █████╗
// ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║       ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝
// ███████║███████╗███████╗███████╗╚██████╗   ██║       ███████║   ██║      ██║   ███████╗███████╗
// ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝       ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝
//

const customStyles = {
	container: base => ({ ...base, width: '100%' }),
	control: base => ({
		...base,
		borderWidth: 0,
		borderRadius: 0,
		outline: 'none',
		boxShadow: 'none !important',
		padding: 0,
		height: '60px',
		background: 'transparent',
	}),
	valueContainer: base => ({
		...base,
		padding: 0,
	}),
	menu: base => ({
		...base,
		zIndex: 10001,
	}),
	placeholder: base => ({
		...base,
		color: Theme.colors.placeholder,
		fontSize: '14px',
		lineHeight: '40px',
	}),
}

const SingleValue = props => {
	const location = props.data
	const icon = useMemo(() => getAutocompleteIcon(location), [location])
	return (
		<SingleValueLabel>
			<FontAwesomeIcon icon={icon} style={{ marginRight: '8px' }} />
			{location.display_name}
			{location.place && location.display_name.indexOf(location.place.name) === -1 && `, ${location.place.name}`}
		</SingleValueLabel>
	)
}

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const NegenTweeAutocomplete = ({ label, value, onChange, name }) => {
	const { t } = useDictionary('negenTweeAutocomplete')
	const { language } = useWidget()

	return (
		<Container>
			<Label className={'text-incolor'} htmlFor={name}>
				{label}
			</Label>
			<AsyncSelect
				// Loading
				cacheOptions
				loadOptions={negenTweeAutocomplete(language)}
				// Value
				value={value}
				onChange={value => onChange && onChange(value ? value : null)}
				// Config
				inputId={name}
				openMenuOnClick={false}
				menuPlacement={'bottom'}
				isClearable
				// Display
				styles={customStyles}
				placeholder={t('placeholder')}
				noOptionsMessage={({ inputValue }) =>
					t(`noResults.${inputValue.length === 0 ? 'empty' : 'forQuery'}`, { query: inputValue })
				}
				loadingMessage={() => t('loading')}
				// Components
				components={{
					IndicatorSeparator: null,
					DropdownIndicator: null,
					Option: NegenTweeAutocompleteSelectOption,
					SingleValue,
				}}
			/>
		</Container>
	)
}
export default NegenTweeAutocomplete
