import React, { useContext } from 'react'

export const WidgetContext = React.createContext({
	language: 'nl',
	widget: null,
	concession: 'keolis',
	device: 'desktop',
})
export const WidgetProvider = WidgetContext.Provider
export const WidgetConsumer = WidgetContext.Consumer

export const useWidget = () => useContext(WidgetContext)
