const HemelBlauw = '#007bc2'
const EmeraldGroen = '#00965e'
const Roze = '#e0457b'
const Oranje = '#ca4f1c'
const Lila = '#9989bd'
const JadeGroen = '#3f8e8b'

const AttentieOranje = '#f98100'
const AttentieGeel = '#f9ab00'

export const Theme = {
	colors: {
		gray: '#999',
		black: '#000',
		white: '#fff',

		primary: HemelBlauw,
		secondary: '#0e3192',
		danger: AttentieOranje,
		info: AttentieGeel,

		bg: {
			gray: '#d8d8d8',
			blue: '#EFF6FB',
			green: '#ebf4f1',
		},

		border: {
			gray: '#B1B3B3',
			blue: HemelBlauw,
		},

		link: '#404040',
		body: '#191919',
		placeholder: '#565858',

		red: Roze,
		green: EmeraldGroen,
		orange: Oranje,
		blue: '#0e3192',
		blueLight: HemelBlauw,
		pink: Roze,
		lila: Lila,
		jade: JadeGroen,
	},

	breakpoints: {
		mobile: '640px',
	},
}
