import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { Theme } from '../../theme'
import SelectNegenTweeRouteItemPartition from './SelectNegenTweeRouteItemLeg'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const EmptyCheck = styled.div`
	box-sizing: border-box;
	width: 18px;
	height: 18px;
	border: 2px solid ${Theme.colors.border.gray};
	border-radius: 2px;
`
const Check = styled.div`
	flex-shrink: 0;
	width: 18px;
`
const Container = styled.div`
	font-size: 18px;
	margin-bottom: 4px;
	padding: 20px 20px;
	display: flex;
	align-items: center;

	&:hover {
		cursor: pointer;
		${EmptyCheck} {
			border-color: ${Theme.colors.border.blue};
		}
	}
`
const Partitions = styled.div`
	flex-grow: 1;
	align-items: center;
	margin-left: 12px;
	display: flex;
	flex-wrap: wrap;
	> * {
		margin-right: 12px;
	}
	> span {
		flex-grow: 1;
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const SelectNegenTweeRouteItem = ({ journey = null, isSelected = false, onClick }) => {
	// Get the non walk partitions
	const legs = useMemo(() => {
		if (!journey) return null
		return journey.legs.filter(leg => leg.modality_group !== 'Walking')
	}, [journey])

	return (
		<Container className={isSelected ? 'bg-primary' : 'bg-light-primary'} isSelected={isSelected} onClick={onClick}>
			<Check>{isSelected ? <FontAwesomeIcon icon={faCheckSquare} /> : <EmptyCheck />}</Check>
			<Partitions>
				{legs ? (
					<>
						{legs.map((leg, index) => (
							<React.Fragment key={index}>
								{index > 0 && (
									<FontAwesomeIcon
										icon={faArrowRight}
										color={isSelected ? undefined : Theme.colors.blue}
									/>
								)}
								<SelectNegenTweeRouteItemPartition key={index} leg={leg} />
							</React.Fragment>
						))}
					</>
				) : (
					<Skeleton height={20} />
				)}
			</Partitions>
		</Container>
	)
}
export default SelectNegenTweeRouteItem
