import * as React from 'react'
import styled from 'styled-components'
import SelectNegenTweeRouteItem from './SelectNegenTweeRouteItem'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	margin-bottom: 16px;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const SelectNegenTweeRoute = ({ journeys, selectedIndex, onChange }) => {
	///////////////
	// Resources //
	///////////////

	return (
		<Container>
			{journeys.map((journey, index) => (
				<SelectNegenTweeRouteItem
					key={index}
					journey={journey}
					isSelected={selectedIndex === index}
					onClick={() => onChange(index)}
				/>
			))}
		</Container>
	)
}
export default SelectNegenTweeRoute
