import { faHome, faMapPin, faTrain } from '@fortawesome/free-solid-svg-icons'

export const getAutocompleteIcon = location => {
	switch (location.type) {
		case 'Station':
			return faTrain

		case 'Adres':
			return faHome

		case 'Halte':
		case 'Poi':
		default:
			return faMapPin
	}
}
